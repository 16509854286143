<template>
    <div v-if="hasPermission" class="empty-cover" @click="openUrlAction()">
        <div class="empty-cover__container">
            <p class="add-cover-icon"><IconSax name="edit" size="24px" /></p>
            <p class="add-cover">{{ $t('empty_state.edit_module') }}</p>
            <p class="add-cover-text">{{ $t('empty_state.recommended_size') }}</p>
            <p class="add-cover-text">{{ $t('empty_state.size') }}</p>
        </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex"
  export default {
      props: {
        course_id: {
            type: Number
        }
      },
    computed: {
        ...mapGetters({
            hasPermission: "user/hasPermission"
        }),
    },
      data() {
          return {};
      },
      methods: {
        openUrlAction() {
            let url = `/config_curso/${this.course_id}/module`;
            this.$router.push(url);
        }
      }
  }
  
  </script>

<style lang="scss" scoped>
.empty-cover{
    &__container{
        cursor: pointer;
        display: flex;
        width: 150px;
        height: 204px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        border: var(--empty-state-border);
        background: var(--empty-state-backgorund);
    }
}

.add-cover{
    margin-bottom: 8px;
    color: #141416;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.2px;

    [data-theme="dark"] & {
        color: #fff;
    }
}

.add-cover-icon div{
    ::v-deep .svg-inner path{
        fill: #9E9E9E;

        [data-theme="dark"] & {
            fill: #fff;
        }
    }
}

.add-cover-icon{
    display: flex;
    justify-content: space-around;
    margin-bottom: 8px;
}

p{
    margin: 0;
}

.add-cover{
    text-align: center;
    margin-bottom: 8px;
    color: #141416;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.6px;

    [data-theme="dark"] & {
    color: #fff;
    }
}

.add-cover-text{
    color:  #878787;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;

    [data-theme="dark"] & {
        color: #9E9E9E;
    }
}
</style>
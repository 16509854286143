<template>
  <div class="commonBoxModule" data-testid="box-module">
    
    <template v-if="getIsLoading">
      <div>
        <div class="skeleton skeleton-rating-video"></div>
        <div class="skeleton-row skeleton-text-control">
          <div class="skeleton skeleton-title-video"></div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="commonBoxModule__titleModule">
        <IconSax size="20px"
          v-if="(!module?.is_liberated || !module?.has_access) && !getIsAdmin"
          :name="module?.is_liberated ? 'lock' : 'calendar-1'"
        />
        <div class="commonBoxModule__header">
          <div class="commonBoxModule__bar">
            <Rating :value="module?.rating" />
            <Progress :value="module?.percentage_complete" />
            <Students :value="module?.students_count" />
          </div>
          <div class="commonBoxModule__title">
            <TextPlay size="lg" weight="semibold" color="var(--fontcolor)" :text="module?.title" />
          </div>
          <div class="commonBoxModule__details" v-if="!module?.has_access"  >
            <span  @click="navToCourse(module)">{{ $t("module.see_details") }}</span>
          </div>
        </div>
      </div>
      <div class="commonBoxModule__editCourse">
        <template v-if="hasPermission && studentCantView && !getIsMobile">
          <EditModeButton :courseId="module?.id" />
        </template>
        <div class="commonBoxModule__controls">
          <div>
            <slot name="controls"></slot>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/* Vuex */
import { mapGetters, mapActions } from "vuex";

/* Components */
import EditModeButton from "@/components/pages/home/admin/EditModeButton.vue";
import Rating from "@/components/common/cards/rating/Rating.vue";
import Progress from "@/components/common/cards/progress/Progress.vue";
import Students from "@/components/common/cards/students/Students.vue";
import EmptyStateModuleCover from "@/components/common/cards/cover/EmptyStateModuleCover.vue";

export default {
  name: "BoxModule",
  components: {
    EditModeButton,
    Rating,
    Progress,
    Students,
    EmptyStateModuleCover
  },
  computed: {
    ...mapGetters({
      getIsAdmin: "config/getIsAdmin",
      hasPermission: "user/hasPermission",
      studentCantView: "user/studentCantView",
      getShowRating: "config/getShowRating",
      getIsMobile: "layout/getIsMobile",
      getSettings: "config/getSettings",
      getHideDisabledCourses: "config/getHideDisabledCourses",
      getIsLoading: "config/getIsLoading",
    }),
  },
  props: {
    module: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    ...mapActions({
      navToCourse: "home/actionNavToCourse"
    }),
    has_access() {
      return !this.module?.is_liberated || !this.module?.has_access
    }
  }
};
</script>

<style lang="scss" scoped>
.commonBoxModule {
  & {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 20px;
    width: 100%;
  }

  &__titleModule {
    display: flex;
    gap: 16px;
  }

  &__lock {
    display: flex;
    align-items: flex-end;
    padding-bottom: 2px;
    &--icon {
      margin-bottom: 0px;
    }
  }

  &__bar {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    gap: 10px;
    @extend .body-semibold-12;
  }

  &__title {
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: flex-end;
    gap: 8px;

    &--text {
      color: var(--fontcolor);
      margin-bottom: 0px;
      @extend .body-semibold-18;
      &__lock {
        color: var(--neutral-gray-600);
      }
    }
  }

  &__details span{
    color: var(--maincolor);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14.4px;
    cursor: pointer;
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  &__editCourse {
    display: flex;
    align-items: center;
    gap: 8px;
    > ::v-deep .custom-button {
      color: var(--neutral-gray-400);
      border: 1px solid var(--neutral-gray-400);
      transition: opacity 0.3s ease;
      &:hover {
        transition: opacity 0.3s ease;
        color: var(--neutral-gray-400);
        background-color: transparent;
        opacity: 0.7;
      }
      > .svgCss {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
